var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sourceForm"},[_c('div',{staticClass:"bottom-wrap"},[_c('search-data',{ref:"search",attrs:{"title":'模型名称：',"placeholder":'关键词',"searchUrl":_vm.getAssetList,"params":_vm.params},on:{"goNextEvent":_vm.goNext}})],1),_c('div',[_c('v-dialog',{attrs:{"title":"新建资产","width":"50%","cancelTxt":"下一步","sureTxt":"取消"},on:{"cancel":_vm.nextStepBtn,"confirm":_vm.cancelBtn},model:{value:(_vm.dialogFit),callback:function ($$v) {_vm.dialogFit=$$v},expression:"dialogFit"}},[_c('form-panel',{ref:"formPanel",staticClass:"form-panel",attrs:{"labelWidth":'110px',"form":_vm.form,"hasHeader":false,"label-position":'left'}},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"label":"资产名称","rules":[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('div',{staticClass:"check-panel"},[_c('el-form-item',{attrs:{"labelWidth":'160px',"label":"资产数据更新周期","rules":[
              { required: true, message: '请输入表达式', trigger: 'blur' },
            ],"prop":"loginName"}},[_vm._l((_vm.sceneIdsList),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":_vm.checkChange},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),_c('v-input',{attrs:{"placeholder":"请输入表达式"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],2)],1),_c('div',{staticClass:"title"},[_vm._v("既有属性模型")]),_c('el-form-item',{attrs:{"label":"选择模型","rules":[
            {
              required: true,
              message: '请输入数据库连接配置',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"选择属性","rules":[
            {
              required: true,
              message: '请输入数据库连接配置',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('div',{staticClass:"title"},[_vm._v("组合模型")]),_c('div',{staticClass:"item-model"},[_c('el-form-item',{attrs:{"label":"选择模型","rules":[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"选择属性","rules":[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1)],1),_c('el-form-item',{attrs:{"label":"关联条件","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"新属性名称","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"新属性字段","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入字段"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"新属性类型","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('div',{staticClass:"check-panel"},[_c('el-form-item',{attrs:{"label":"取值范围","rules":[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入字段"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('div',{staticStyle:{"margin":"0 10px"}},[_vm._v("~")]),_c('el-form-item',{attrs:{"label-width":'10px',"rules":[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入字段"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"title":"新建资产 属性配置|分析配置","width":"50%","cancelTxt":"上一步","sureTxt":"确定"},on:{"cancel":_vm.backStepBtn,"confirm":_vm.confirm},model:{value:(_vm.dialogComputed),callback:function ($$v) {_vm.dialogComputed=$$v},expression:"dialogComputed"}},[_c('form-panel',{ref:"formPanel",attrs:{"form":_vm.form,"hasHeader":false}},[_c('el-form-item',{attrs:{"label":"选择属性","rules":[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1),_c('el-form-item',{attrs:{"label":"计算表达式","rules":[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入数据源名称"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"关联关系","rules":[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],"prop":"loginName"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.options}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }