<template>
  <div class="sourceForm">
    <div class="bottom-wrap">
      <search-data
        ref="search"
        :title="'模型名称：'"
        :placeholder="'关键词'"
        @goNextEvent="goNext"
        :searchUrl="getAssetList"
        :params="params"
      ></search-data>
    </div>
    <div>
      <v-dialog
        title="新建资产"
        v-model="dialogFit"
        width="50%"
        cancelTxt="下一步"
        sureTxt="取消"
        @cancel="nextStepBtn"
        @confirm="cancelBtn"
      >
        <form-panel
          :labelWidth="'110px'"
          ref="formPanel"
          :form="form"
          :hasHeader="false"
          :label-position="'left'"
          class="form-panel"
        >
          <div class="title">基本信息</div>
          <el-form-item
            label="资产名称"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="loginName"
          >
            <v-input
              placeholder="请输入名称"
              v-model="form.loginName"
            ></v-input>
          </el-form-item>
          <div class="check-panel">
            <el-form-item
              :labelWidth="'160px'"
              label="资产数据更新周期"
              :rules="[
                { required: true, message: '请输入表达式', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in sceneIdsList"
                :key="index"
                :index="index"
                type="border"
                :label="item.label"
                @change="checkChange"
              />
              <v-input
                placeholder="请输入表达式"
                v-model="form.loginName"
              ></v-input>
            </el-form-item>
          </div>
          <div class="title">既有属性模型</div>
          <el-form-item
            label="选择模型"
            :rules="[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options" />
          </el-form-item>
          <el-form-item
            label="选择属性"
            :rules="[
              {
                required: true,
                message: '请输入数据库连接配置',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options" />
          </el-form-item>
          <div class="title">组合模型</div>
          <div class="item-model">
            <el-form-item
              label="选择模型"
              :rules="[
                {
                  required: true,
                  message: '请输入数据库连接配置',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options" />
            </el-form-item>
            <el-form-item
              label="选择属性"
              :rules="[
                {
                  required: true,
                  message: '请输入数据库连接配置',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options" />
            </el-form-item>
          </div>

          <el-form-item
            label="关联条件"
            :rules="[
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options" />
          </el-form-item>
          <el-form-item
            label="新属性名称"
            :rules="[
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-input
              placeholder="请输入名称"
              v-model="form.loginName"
            ></v-input>
          </el-form-item>
          <el-form-item
            label="新属性字段"
            :rules="[
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-input
              placeholder="请输入字段"
              v-model="form.loginName"
            ></v-input>
          </el-form-item>
          <el-form-item
            label="新属性类型"
            :rules="[
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
              },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options" />
          </el-form-item>
          <div class="check-panel">
            <el-form-item
              label="取值范围"
              :rules="[
                {
                  required: true,
                  message: '请输入数据库连接配置',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入字段"
                v-model="form.loginName"
              ></v-input>
            </el-form-item>
            <div style="margin: 0 10px">~</div>
            <el-form-item
              :label-width="'10px'"
              :rules="[
                {
                  required: true,
                  message: '请输入数据库连接配置',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入字段"
                v-model="form.loginName"
              ></v-input>
            </el-form-item>
          </div>
        </form-panel>
      </v-dialog>
      <v-dialog
        title="新建资产 属性配置|分析配置"
        v-model="dialogComputed"
        width="50%"
        cancelTxt="上一步"
        sureTxt="确定"
        @cancel="backStepBtn"
        @confirm="confirm"
      >
        <form-panel ref="formPanel" :form="form" :hasHeader="false">
          <el-form-item
            label="选择属性"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options" />
          </el-form-item>
          <el-form-item
            label="计算表达式"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="loginName"
          >
            <v-input
              placeholder="请输入数据源名称"
              v-model="form.loginName"
            ></v-input
          ></el-form-item>

          <el-form-item
            label="关联关系"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="loginName"
          >
            <v-select clearable :options="options"
          /></el-form-item>
        </form-panel>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import searchData from "../components/search/index.vue";
import { getAssetList } from "./api.js";
export default {
  components: {
    searchData,
  },
  data() {
    return {
      getAssetList,
      params: {
        curPage: 1,
        pageSize: 12,
        name: undefined,
        type: undefined,
      },
      submitConfig: {
        queryUrl: "aaa",
        submitUrl: "bb",
      },
      sceneIdsList: [
        { checked: true, label: "实时" },
        { checked: true, label: "固定周期（cron表达式）" },
      ],
      labelPosition: "right",
      dialogFit: false,
      dialogComputed: false,
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      headers: [
        {
          prop: "problemName",
          label: "名称",
        },
        {
          prop: "problemType",
          label: "备注",
        },
        {
          prop: "ifRequired",
          label: "类型",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      activeName: "first",
      form: {
        id: "", // 数据唯一值
        userName: "", // 姓名（昵称）
        mobileNum: "", // 手机号
        loginName: "", // 登陆账户
        loginPwd: "", // 登陆密码
        newPasswordRepeat: "", // 确认密码
        manageGroupId: "", // 管理组id
        roleIds: "", // 角色id
        userSource: 3, // 固定
        isRegister: 4, // 固定
        requestList: [
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.$refs.search.getList(this.params);
  },
  methods: {
    // 下一步
    nextStepBtn() {
      this.dialogFit = false;
      this.dialogComputed = true;
    },
    // 取消
    cancelBtn() {
      this.dialogFit = false;
    },
    //上一步
    backStepBtn() {
      this.dialogComputed = false;
      this.dialogFit = true;
    },
    // 确定
    confirm() {
      this.dialogComputed = false;
    },
    handleCommand(command) {
      console.log(command);
    },
    handleClick(tab, event) {
      console.log(this.activeName);
    },
    goNext(type) {
      this.dialogFit = true;
    },
    checkChange() {},
  },
};
</script>

<style lang="less" scoped>
.form-panel {
  padding: 0 30px;
}
.check-panel {
  display: flex;
}
.item-model {
  display: flex;
  justify-content: space-between;
}

.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    padding: 30px 20px;
    height: calc(100% - 150px);
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .mt50 {
      margin-top: 30px;
    }
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 30px;
}
</style>
