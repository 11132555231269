//获取模型管理列表
const getModelList = `/gateway/hc-data/datacenter/model/page`;
//获取资产管理列表
const getAssetList = `/gateway/hc-data/datacenter/asset/page`;
//获取存储管理列表
const getStoreList = `/gateway/hc-data/datacenter/store/page`;
//获取数据分析列表
const getAnalysisList = `/gateway/hc-data/datacenter/analysis/page`;
//模型管理详情
const getModelDetail = `/gateway/hc-data/datacenter/model/detail`;
//删除数据模型
const removeMode = `/gateway/hc-data/datacenter/model/remove`;
//提交模型配置信息
const addModel = `/gateway/hc-data/datacenter/model/submit`;
//获取数据源列表
const getSourceList = `/gateway/hc-data/datacenter/source/page`;
//获取数据源详情
const sourceDetailUrl = `/gateway/hc-data/datacenter/source/detail`;
//获取计算符号
const getCode = `/gateway/blade-system/dict/list?code=operator`;
//获取存储管理详情
const getStoreDetail = `/gateway/hc-data/datacenter/store/detail`;
//获取加减乘除计算符号
const getSymbol = `/gateway/blade-system/dict/list?code=aggregation`;
//预览 获取表字段信息
const watchInfo = `/gateway/hc-data/datacenter/source/field/list`
//获取属性列表
const getModelDetailUrl = `/gateway/hc-data/datacenter/model/field/list`;
//获取检验规则数据
const getInSpectionRuleOpt = `/gateway/hc-portal/security/pageList`;
export {
  getModelList,
  getAssetList,
  getStoreList,
  getAnalysisList,
  getModelDetail,
  removeMode,
  addModel,
  getSourceList,
  sourceDetailUrl,
  getCode,
  getStoreDetail,
  getSymbol,
  watchInfo,
  getModelDetailUrl,
  getInSpectionRuleOpt
};
